import React from "react";
import styles from "./styles.module.css";

const data = [
  {
    title: "Mexico City",
    direction: "Homero 1425 11560, Polanco",
    city: "CDMX, México",
    email: "info@keenata.com",
    telf: "+49 (030) 20.84.89.040",
    image:
      "https://firebasestorage.googleapis.com/v0/b/keenata-2cd78.appspot.com/o/images%2FContact%2Fcontact-mexico-coffee-v-2.png?alt=media&token=f9f5e265-6859-456e-a1ad-3ac32df4d52d",
    background: "#FC5691",
    titleColor: "#FFF",
    textColor: "#FFF",
  },
  {
    title: "Berlin",
    direction: "Brunnenstr. 145, 10115, Mitte",
    city: "Berlin, Germany",
    telf: "+49 (030) 20.84.89.040",
    email: " info@keenata.com",
    image:
      "https://firebasestorage.googleapis.com/v0/b/keenata-2cd78.appspot.com/o/images%2FContact%2Fcontact-berlin-coffee-2.png?alt=media&token=24685a58-fe4d-48de-bc42-9775304e4008",
    background: "#E2E2E2",
    titleColor: "black",
    textColor: "#666",
  },
];

const Contact = () => (
  <div className={styles.container} id="contact">
    <div className={styles.wrapper}>
      {data.map((item) => (
        <div
          className={styles.option}
          style={{ background: item.background, color: item.textColor }}
        >
          <div
            className={styles.optionTitle}
            style={{ color: item.titleColor }}
          >
            {item.title}
          </div>
          {item.title === "Mexico City" ? (
            <div className={styles.reactangleWhite}></div>
          ) : (
            <div className={styles.reactangleRed}></div>
          )}
          <div className={styles.optionDir}>{item.direction}</div>
          <div className={styles.optionDir}>{item.city}</div>
          <div className={styles.imageContainer}>
            <img src={item.image} alt="" />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Contact;
