import React from "react";
import classnames from "classnames/bind";
import skyImage from "../../../assets/images/icons/sky.jpg";
import carpetImage from "../../../assets/images/icons/carpet.png";
import tableImage from "../../../assets/images/icons/table.png";
import chairImage from "../../../assets/images/icons/chair.png";
import windowImage from "../../../assets/images/icons/window.png";
import plant1Image from "../../../assets/images/icons/plant1.png";
import laptopImage from "../../../assets/images/icons/laptop.png";
import booksImage from "../../../assets/images/icons/books.png";
import coffeImage from "../../../assets/images/icons/coffe.png";
import lentesImage from "../../../assets/images/icons/lentes.png";
import mouseImage from "../../../assets/images/icons/mouse.png";
import plant2Image from "../../../assets/images/icons/plant2.png";
import lampImage from "../../../assets/images/icons/lamp.png";
import pensImage from "../../../assets/images/icons/pens.png";
import womanImage from "../../../assets/images/icons/woman.png";
import buttonImage from "../../../assets/button.png";
import Steps from "../../../components/Steps";
import Title from "../../../components/Title";
import styles from "./styles.module.css";

const cx = classnames.bind(styles);

const stepsMap = [
  ["sky", "table", "carpet", "laptop", "code"],
  [
    "sky",
    "table",
    "carpet",
    "laptop",
    "window",
    "plant2",
    "books",
    "chair",
    "cloud",
  ],
  [
    "sky",
    "table",
    "carpet",
    "laptop",
    "window",
    "plant2",
    "books",
    "chair",
    "plant1",
    "lamp",
    "mouse",
    "ci",
  ],
  [
    "sky",
    "table",
    "carpet",
    "laptop",
    "window",
    "plant2",
    "books",
    "chair",
    "plant1",
    "lamp",
    "mouse",
    "pens",
    "quality",
  ],
  [
    "sky",
    "table",
    "carpet",
    "laptop",
    "window",
    "plant2",
    "books",
    "chair",
    "plant1",
    "lamp",
    "mouse",
    "pens",
    "lentes",
    "coffe",
    "woman",
    "team",
  ],
];

function out(array, key) {
  return array.indexOf(key) === -1;
}

class Office extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      block: false,
    };
  }
  componentDidMount() {
    this.target = document.getElementById("office");
    this.target.addEventListener("wheel", this.scroll);
  }
  componentWillUnmount() {
    this.target.removeEventListener("wheel", this.scroll);
  }
  set = (step) => {
    this.target.scrollIntoView({ behavior: "smooth" });
    this.setState({ step });
  };
  scroll = (e) => {
    const { step } = this.state;

    if (
      (e.wheelDeltaY < 0 && stepsMap[step + 1]) ||
      (e.wheelDeltaY > 0 && stepsMap[step - 1])
    ) {
      e.stopPropagation();
      e.preventDefault();
      this.target.scrollIntoView({ behavior: "smooth" });
    }

    if (!this.state.block && e.wheelDeltaY < 0 && stepsMap[step + 1]) {
      this.setState({ step: step + 1, block: true });
    }
    if (!this.state.block && e.wheelDeltaY > 0 && stepsMap[step - 1]) {
      this.setState({ step: step - 1, block: true });
    }

    clearTimeout(this.clock);
    this.clock = setTimeout(() => this.setState({ block: false }), 80);
  };
  render() {
    const { step } = this.state;
    const { texts, onSelect } = this.props;
    const array = stepsMap[step] || [];
    return (
      <div className={styles.office} id="office">
        <img src={skyImage} className={cx("sky")} alt="" />
        <div className={styles.animationWrapper}>
          <img
            src={windowImage}
            className={cx("window", { windowOut: out(array, "window") })}
            alt=""
          />
          <img
            src={carpetImage}
            className={cx("carpet", { carpetOut: out(array, "carpet") })}
            alt=""
          />
          <img
            src={chairImage}
            className={cx("chair", { chairOut: out(array, "chair") })}
            alt=""
          />
          <img
            src={plant1Image}
            className={cx("plant1", { plant1Out: out(array, "plant1") })}
            alt=""
          />
          <img
            src={tableImage}
            className={cx("table", { tableOut: out(array, "table") })}
            alt=""
          />
          <img
            src={mouseImage}
            className={cx("mouse", { mouseOut: out(array, "mouse") })}
            alt=""
          />
          <img
            src={laptopImage}
            className={cx("laptop", { laptopOut: out(array, "laptop") })}
            alt=""
          />
          <img
            src={booksImage}
            className={cx("books", { booksOut: out(array, "books") })}
            alt=""
          />
          <img
            src={coffeImage}
            className={cx("coffe", { coffeOut: out(array, "coffe") })}
            alt=""
          />
          <img
            src={lentesImage}
            className={cx("lentes", { lentesOut: out(array, "lentes") })}
            alt=""
          />
          <img
            src={lampImage}
            className={cx("lamp", { lampOut: out(array, "lamp") })}
            alt=""
          />
          <img
            src={plant2Image}
            className={cx("plant2", { plant2Out: out(array, "plant2") })}
            alt=""
          />
          <img
            src={pensImage}
            className={cx("pens", { pensOut: out(array, "pens") })}
            alt=""
          />
          <img
            src={womanImage}
            className={cx("woman", { womanOut: out(array, "woman") })}
            alt=""
          />
        </div>
        {texts.map((item) => (
          <>
            <div className={cx("title", { titleOut: out(array, item.key) })}>
              <Title label={item.title} />
            </div>
            <div
              className={cx("textBox", { textBoxOut: out(array, item.key) })}
            >
              <div className={styles.mainText}>{item.text}</div>
              {item.texts.map((bullet) => (
                <div className={styles.bullets}>{bullet}</div>
              ))}
              <button onClick={() => onSelect(item.key)}>
                <div className={styles.buttonContainer}>
                  <img
                    className={styles.imageButton}
                    src={buttonImage}
                    alt=""
                  />
                  <p> Read More</p>
                </div>
              </button>
            </div>
          </>
        ))}

        <div className={styles.stepsWrapper}>
          <Steps options={stepsMap} current={step} onClick={this.set} />
        </div>
      </div>
    );
  }
}

export default Office;
