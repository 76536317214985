import React from "react";
import Title from "../../../components/Title";
import terraFormImage from "../../../assets/logos/logos-terraform.png";
import kubernetesImage from "../../../assets/logos/logos-kubernetes.png";
import awsImage from "../../../assets/logos/logos-aws.png";
import firebaseImage from "../../../assets/logos/logos-firebase.png";
import dataDogImage from "../../../assets/logos/logos-datadog.png";
import dockerImage from "../../../assets/logos/logos-docker.png";
import styles from "./styles.module.css";

const pdfUrl = "https://content.keenata.com/Keenata-HL.pdf";

const data = {
  title: "Keenata is made up of a team of digital storytellers. ",
  text: [
    "We are a tool used by brands and creative agencies to express their essence, streamline their processes, and to get to know their base. We offer a full suite of services, including website development and mobile app solutions, consulting on industry practices and UX design, that our clients rely on to stay ahead of the curve.",
  ],

  phrase: [
    "Think of us as the swiss army knife of software development; there’s nothing we don’t code.  ",
  ],
  logos: [
    terraFormImage,
    kubernetesImage,
    awsImage,
    firebaseImage,
    dataDogImage,
    dockerImage,
  ],
};

const About = () => (
  <div className={styles.container} id="about">
    <div className={styles.titleContainer}>
      <Title label="Why we exist" color="#000" />
    </div>
    <div className={styles.textWrapper}>
      <div className={styles.phraseContainer}>{data.phrase}</div>
      <div>
        <h3 className={styles.subTitle}>{data.title}</h3>
        <p className={styles.subTitleText}>{data.text}</p>
      </div>
    </div>

    <div className={styles.logosWrapper}>
      {data.logos.map((item) => (
        <img src={item} alt="" />
      ))}
    </div>
    <div className={styles.pdfContainer}>
      <a className={styles.subTitleTextPDF} href={pdfUrl}>
        DOWNLOAD FLYER
      </a>
    </div>
  </div>
);

export default About;
