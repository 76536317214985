import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

export default class Footer extends React.Component {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.contact}>
          <div className={styles.email}>hi@keenata.com</div>
          <div className={styles.number}>+49 (30) 208.489.040</div>
        </div>
        <div className={styles.links}>
          <Link to="/legal">Imprint / Legal</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
      </div>
    );
  }
}
