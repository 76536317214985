export const services = [
  {
    key: "code",
    title: "Code Development",
    categories: [
      {
        subTitleName: "Mobile App Development",
        infos: [
          {
            infoText: "Reactive Apps with great performance",
          },
          {
            infoText: "We build Modular and re-usable components",
          },
        ],
      },
      {
        subTitleName: "Web Development",
        infos: [
          {
            infoText: "We separate the UI from the API",
          },
          {
            infoText:
              "Make sure the User Experience is a priority throughout the design process",
          },
          {
            infoText:
              "The platform is adapted to be viewed from any angle on multiple devices ",
          },
        ],
      },
      {
        subTitleName: "API Micro-services",
        infos: [
          {
            infoText:
              "Micro-services help to minimize operational risk by giving you the flexibility to deploy self containing services.",
          },
          {
            infoText: "∙  Keep business logic clean and organized",
          },
          {
            infoText:
              "∙  Interact with other services in a predictable and structured way ",
          },
          {
            infoText: "∙  Use different tech stacks as need",
          },
          {
            infoText:
              "∙  Ability to deploy new versions and roll back quickly if necessary",
          },
        ],
      },
    ],
  },
  {
    key: "cloud",
    title: "Cloud Configuration",
    categories: [
      {
        subTitleName: "Architecture and Design",
        infos: [
          {
            infoText:
              "We’ll create the blueprint responsible for organizing the system",
          },
          {
            infoText: "Setting guidelines",
          },
          {
            infoText: "Assessing risks and implementing technologies",
          },
        ],
      },
      {
        subTitleName: "Setup and Configuration",
        infos: [
          {
            infoText:
              "We’ll help you to get and stay organized by storing and versioning your artifacts in a binary repository,",
          },
          {
            infoText:
              "applying DNS names to all in and out points and storing 3rd party API Credentials in a secret vault.             ",
          },
        ],
      },
      {
        subTitleName: "Operation and Quality Control ",
        infos: [
          {
            infoText:
              "When taking your application to production, things change quickly. By collecting and securing your data and choosing high quality services,  amongst other things, you can ensure a smooth operational transition.             ",
          },
        ],
      },
      {
        subTitleName: "Monitoring and Alerting  ",
        infos: [
          {
            infoText:
              "Monitoring tools are the only way to operate a platform in a responsible manner. ",
          },
          {
            infoText:
              "We use a variety of services to help detect anomalies, alert your team when they are found, track network traffic, application performance and memory usage. ",
          },
        ],
      },
      {
        subTitleName: "Security  ",
        infos: [
          {
            infoText:
              "We comply by all security regulations and have the certificates to prove it. ",
          },
        ],
      },
    ],
  },
  {
    key: "ci",
    title: "Continous Integration ",
    categories: [
      {
        subTitleName: "Open Source APIs",
        infos: [
          {
            infoText:
              "We’ll help to establish best practices for implementing Kubernetes, an open source technology, that allows for the implementation of an orchestration system that allows different technologies to be deployed into the same virtual cloud             ",
          },
        ],
      },
      {
        subTitleName:
          "Identify development, testing and deployment cycles via Pipelines",
        infos: [
          {
            infoText:
              "A collaborative effort between developers, backups, and automated code-related tasks, we are able to confidently release updates with interruption and quickly recover from mistakes when necessary.",
          },
        ],
      },
      {
        subTitleName: "Pipelines",
        infos: [
          {
            infoText:
              "Keenata uses pipelines to automate continuous integration. ",
          },
          {
            infoText:
              "This ensures development cycles operate continuously and avoid negative individual dependencies.              ",
          },
        ],
      },
    ],
  },
  {
    key: "quality",
    title: "Quality Assurance ",
    categories: [
      {
        subTitleName: "Code Reviews",
        infos: [
          {
            infoText:
              "Code reviews and developing code at the same time can be time consuming for one team. ",
          },
          {
            infoText:
              " Keenata shares the burden by reviewing the code each time a pull request is created so that your team can stay focused. ",
          },
        ],
      },
      {
        subTitleName: "Unit Testing",
        infos: [
          {
            infoText:
              "We’ll test source code again and again to ensure it fits with the existing procedure so all deployments are smooth sailing. ",
          },
        ],
      },
      {
        subTitleName: "DevOps work as one ",
        infos: [
          {
            infoText:
              "A delivery team comprised of software developers and quality assurance testers are more invested in the future of the system when they work together than when they work separately.             ",
          },
        ],
      },
    ],
  },
  {
    key: "team",
    title: "Team Empowerment",
    categories: [
      {
        subTitleName: "Training Courses",
        infos: [
          {
            infoText:
              "Providing continuous training to your team is a great way to keep your team motivated and engaged.",
          },
          {
            infoText:
              "Our courses cover emerging technologies and frameworks that your team will love learning about. ",
          },
        ],
      },
      {
        subTitleName: "Project Management",
        infos: [
          {
            infoText:
              "All of our teams of 3 or more developers include a project management component that are responsible for assessing risk, budget planning, quality control and strategic release cycles planning. ",
          },
        ],
      },
      {
        subTitleName: "Smart Onboarding",
        infos: [
          {
            infoText:
              "We help companies minimize the time between hiring someone new and the day they start writing code.",
          },
        ],
      },
      {
        subTitleName: "Remote Teams",
        infos: [
          {
            infoText:
              "We are able to extend your reach, strength, and workdays by working in tandem with remote teams. ",
          },
        ],
      },
    ],
  },
];

export const texts = [
  {
    key: "code",
    title: "Code Development",
    text:
      "We apply 20 years of software development experience + a deep love of technology to your project. If you want the best outcome possible, you’ve come to the right place.  ",
    texts: [
      "01  API Micro Services",
      "02  Mobile App Development ",
      "03  Web Development",
    ],
  },
  {
    key: "cloud",
    title: "Cloud Configuration",
    text:
      "To scale software best, one must prioritize being organized, aware, and alert. ",
    texts: [
      "01  Architecture and Design",
      "02  Setup and Configuration",
      "03  Operation & Quality Control",
      "04  Monitoring and Alerting",
      "05  Security",
    ],
  },
  {
    key: "ci",
    title: "Continous Integration",
    text:
      "We use agile methodologies that respond quickly to code changes, ensuring smooth and constant integration",
    texts: [
      "01  Open source APIs",
      "02  Identify cycles of development, testing and fixing",
      "03  Pipelines",
    ],
  },
  {
    key: "quality",
    title: "Quality Control",
    text: "We are meticulous when it comes to coding an uninterrupted service.",
    texts: [
      "01  Code reviews",
      "02  Unitary testing",
      "03  DevOps work together as one team",
    ],
  },
  {
    key: "team",
    title: "Team Empowerment",
    text:
      "A strong foundation is a necessary first step in building something everlasting    ",
    texts: [
      "01  Training courses",
      "02  Project management",
      "03  Smarter onboarding",
    ],
  },
];
