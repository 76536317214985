import React from "react";
import logoImage from "../../assets/logoName.png";
import crossImage from "../../assets/cross.png";
import styles from "./styles.module.css";

export default class CodeDevModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { block: false };
  }
  componentDidMount() {
    this.target = document.getElementById("serviceModal");
    this.target.addEventListener("wheel", this.scroll);
  }
  componentWillUnmount() {
    this.target.removeEventListener("wheel", this.scroll);
  }
  scroll = (e) => {
    e.stopPropagation();
  };

  render() {
    const { service, onClose } = this.props;

    const { title, categories } = service || { title: "", categories: [] };

    return (
      <div
        className={styles.container}
        id="serviceModal"
        style={{ display: service ? "block" : "none" }}
      >
        <div className={styles.modalHeader}>
          <div className={styles.logoContainer}>
            <img className={styles.logoStyle} src={logoImage} alt="" />
          </div>
          <button onClick={onClose}>
            <img className={styles.crossContainer} src={crossImage} alt="" />
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.titleText}>{title}</div>
          <div className={styles.categoriesList}>
            {categories.map((category, index) => (
              <div className={styles.subCategoryContainer}>
                <div className={styles.subTitleContainer}>
                  {"0" + (index + 1)} {category.subTitleName}
                </div>
                <div classname={styles.infoWrapper}>
                  <ul>
                    {category.infos.map((info) => (
                      <li className={styles.listElement}> {info.infoText}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
