import React from "react";
import logoImage from "../../assets/keenata.png";
import styles from "./styles.module.css";

const pdfUrl = "https://content.keenata.com/Keenata-HL.pdf";

const links = [
  { to: "about", text: "WHY WE EXIST " },
  { to: "contact", text: "CONTACT" },
];

function scrollTo(id) {
  const target = document.getElementById(id);
  target.scrollIntoView({ behavior: "smooth" });
}

const Navbar = () => (
  <div className={styles.navbar}>
    <div className={styles.wrapper}>
      <div className={styles.logoContainer}>
        <img className={styles.logoSize} src={logoImage} alt="keenata" />
      </div>
      <div className={styles.links}>
        {links.map((item) => (
          <button className={styles.button} onClick={() => scrollTo(item.to)}>
            {item.text}
          </button>
        ))}
        <a className={styles.button} href={pdfUrl}>
          DOWNLOAD FLYER
        </a>
      </div>
      <div className={styles.rectangleContainer}>
        <div className={styles.rectangle}></div>
        <div className={styles.software}>
          <p>Software design studio</p>
        </div>
      </div>
    </div>
  </div>
);

export default Navbar;
