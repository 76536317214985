import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleSolid } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.css";

const Steps = ({ options, current, onClick }) => (
  <div className={styles.steps}>
    {options.map((option, index) => (
      <button onClick={() => onClick(index)} key={index}>
        <FontAwesomeIcon icon={index === current ? faCircleSolid : faCircle} />
      </button>
    ))}
  </div>
);

export default Steps;
