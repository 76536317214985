import React from "react";
import classnames from "classnames/bind";
import styles from "./styles.module.css";

const cx = classnames.bind(styles);

const Modal = ({ children, onClose, open }) => {
  return (
    <div
      className={cx(["container", open ? "containerOpen" : "containerClosed"])}
      onClick={onClose}
    >
      <div className={cx(["wrapper", open ? "wrapperOpen" : "wrapperClosed"])}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
