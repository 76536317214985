import React from "react";
import localforage from "localforage";
import Modal from "../Modal";
import styles from "./styles.module.css";

export default class CookiesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    setTimeout(() => this.load(), 3000);
  }
  load = async () => {
    try {
      const value = await localforage.getItem("cookies");
      console.log(value);
      if (!value) {
        this.setState({ open: true });
      }
    } catch (err) {
      // This code runs if there were any errors.
      console.log(err);
    }
  };
  save = async () => {
    try {
      await localforage.setItem("cookies", true);
      this.setState({ open: false });
    } catch (err) {
      // This code runs if there were any errors.
      console.log(err);
    }
  };
  render() {
    return (
      <Modal open={this.state.open}>
        <p className={styles.text}>
          This site uses cookies in order to provide you with the best possible
          service.
        </p>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={this.save}>
            Agree
          </button>
        </div>
      </Modal>
    );
  }
}
