import firebase from "@firebase/app";
import "@firebase/auth";
import "@firebase/firestore";
import "@firebase/analytics";

firebase.initializeApp({
  apiKey: "AIzaSyAI1m4o8MD8LXoDZqDhfRDg-dDxiKv2xkc",
  authDomain: "keenata-2cd78.firebaseapp.com",
  databaseURL: "https://keenata-2cd78.firebaseio.com",
  projectId: "keenata-2cd78",
  storageBucket: "keenata-2cd78.appspot.com",
  messagingSenderId: "1037784377758",
  appId: "1:1037784377758:web:1e2bc00d7a56bb0a160e8f",
  measurementId: "G-85LJHR1B3E",
});

firebase.analytics();

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export default firebase;

export function getFirestore() {
  if (firebase.firestore) {
    return firebase.firestore();
  }

  throw new Error("firestore-not-initialized");
}

export function getColRef(collection) {
  return getFirestore().collection(collection);
}

export function getDocRef(collection, document) {
  if (collection && document) {
    return getColRef(collection).doc(document);
  }
  return getColRef(collection).doc();
}
