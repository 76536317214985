import React, { Component } from "react";
import Intro from "./Intro";
import Office from "./Office";
import About from "./About";
import Contact from "./Contact";
import CodeDevServices from "../../components/CodeDevServices";
import Footer from "../../components/Footer";
import CookiesModal from "../../components/CookiesModal";
import { services, texts } from "../../constants/services";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: null,
    };
  }
  render() {
    const { service } = this.state;
    return (
      <>
        <CookiesModal />
        <CodeDevServices
          service={service}
          onClose={() => this.setState({ service: null })}
        />
        <Intro />
        <Office
          texts={texts}
          services={services}
          onSelect={(key) =>
            this.setState({
              service: services.find((item) => item.key === key),
            })
          }
        />
        <About />
        <Contact />
        <Footer />
      </>
    );
  }
}

export default Home;
