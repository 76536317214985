import React from "react";
import style from "./styles.module.css";

const Title = (props) => (
  <h2 className={style.title} style={{ color: props.color }}>
    {props.label}
  </h2>
);

export default Title;
