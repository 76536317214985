import React from "react";
import Title from "../../components/Title";
import logoImage from "../../assets/logo4.png";
import styles from "./styles.module.css";

const Legal = () => (
  <div className={styles.legal}>
    <Title label="Imprint" color="#000" />
    <div className={styles.wrapper}>
      <div className={styles.info}>
        <p>Keenata GmbH</p>
        <p>Brunnenstrasse 145</p>
        <p>10115 Berlin</p>
        <p>Phone +49 (30) 208.489.040</p>
        <p>Internet: www.keenata.com</p>
        <p>E-Mail: info [at] keenata.com</p>

        <h4>Managing Director: </h4>
        <p>Juan Pablo Lajud (Chairman)</p>

        <h4>Regulators</h4>
        <p>Registered at:: Amtsgericht Charlottenburg: HRB 186899 B</p>
        <p>Value Added Tax-ID:: DE312551716</p>

        <h4>Editor and content responsible</h4>
        <p>Keenata GmbH</p>
        <p>Corporate communication</p>
        <p> Astrid Bosch (Head of corporate communications) </p>
        <p>E-Mail: abosch [at] keenata.com</p>
      </div>
      <div className={styles.logoContainer}>
        <img src={logoImage} alt="keenata" />
      </div>
    </div>
  </div>
);

export default Legal;
