import React from "react";
import NavBar from "../../../components/NavBar";
import styles from "./styles.module.css";

const Intro = () => (
  <div>
    <div className={styles.intro}>
      <NavBar />
      <div className={styles.wrapper}>
        <div className={styles.rectangleContainer}>
          <div className={styles.rectangle}></div>
          <div>
            <p className={styles.textStyle}>Hi! We’re</p>
          </div>
        </div>
        <div>
          <h3 className={styles.title}>
            A software design studio created to help clients develop and get the
            most out of their digital platforms.
          </h3>
        </div>
      </div>
    </div>
  </div>
);

export default Intro;
