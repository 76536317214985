import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import Home from "./containers/Home";
import About from "./containers/Home/About";
import Contact from "./containers/Home/Contact";
import Privacy from "./containers/Privacy";
import Legal from "./containers/Legal";
import "./utils/firebase";
import "./App.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/privacy" component={Privacy} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/legal" component={Legal} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
}

export default App;
